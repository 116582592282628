import { STATUS_SUCCESS } from 'appConstants';
import { AsyncModal, type TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { Modal, Toast } from '@avast/react-ui-components';
import { Formik } from 'formik';
import type { TWidget, TWidgetActionPayload, TWidgetModalProps } from 'submodule/widgets';
import { preSubmitWidget } from 'submodule/widgets/utils';
import { useAllowedWidgets, useDefaultWidgetValues } from 'submodule/widgets/hooks';
import { WidgetForm } from 'submodule/widgets/forms';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { logParsedError } from 'js/utils/error';

type TAsyncWidgetModalProps = TAsyncModalContainerProps<TWidgetModalProps> & {
	onSubmit: (data: TWidgetActionPayload) => void;
};

export const AsyncWidgetModal = (props: TAsyncWidgetModalProps) => {
	const { forwardedRef, onSubmit } = props;
	const { t } = useTranslation('submodules');
	const widgets = useAllowedWidgets();
	const defaultWidgetValues = useDefaultWidgetValues();

	return (
		<AsyncModal<TWidgetModalProps> ref={forwardedRef} show size="sm" backdrop="static" testId="widgetModal">
			{({ title, index, initialValues = defaultWidgetValues, action, coords }) => (
				<>
					<Modal.Header className="pb-0">{title}</Modal.Header>
					<Modal.Body className="py-4">
						<Formik<TWidget>
							// @ts-ignore
							initialValues={initialValues}
							onSubmit={(values: TWidget, { setSubmitting }) => {
								try {
									const preSubmittedValues = preSubmitWidget(widgets, values);
									if (action === 'ADD_WIDGET') {
										onSubmit({ values: preSubmittedValues, coords });
									} else {
										onSubmit({ values: preSubmittedValues, coords, index });
									}
									forwardedRef.current?.onSuccess(STATUS_SUCCESS);
								} catch (error) {
									logParsedError({
										message: 'Widget preSubmit failed',
										error,
									});
									toast.error(
										<Toast caption={t('error:unspecifiedError')}>
											<p>{t('error:common.repeatOrContactSales')}</p>
										</Toast>,
									);
									setSubmitting(false);
								}
							}}
						>
							<WidgetForm isUpdate={action === 'EDIT_WIDGET'} />
						</Formik>
					</Modal.Body>
				</>
			)}
		</AsyncModal>
	);
};

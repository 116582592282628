import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { RouteName } from 'module/RouteName';
import type { IPromotionListFilter, TEntityPromotion } from 'module/promotions';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { useApiPromotionList } from 'module/promotions/hooks/useApiPromotion';
import { PromotionFilter } from 'module/promotions/components';
import { ButtonComposition } from '@avast/react-ui-components';
import { LinkButton } from 'js/components/atoms/Button';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { ExportPromotionsButton, ExtraFiltersButton } from 'module/promotions/components/buttons';
import type { IApiSortBy } from 'types/api';
import { Can } from 'js/components/molecules/Can';
import type { IListMetaDataValues } from 'types/utils';
import { usePromotionListColumns } from 'module/promotions/hooks';
import { SortDirectionEnum } from 'js/enums';

type TTableData = TEntityPromotion;
type TTableDataFilter = IPromotionListFilter;

export const PageList = (): ReactElement => {
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const promoFilterModalRef = useAsyncModalRef();

	const [listMetaData, setListMetaData] = useState<IListMetaDataValues<TTableDataFilter>>({});

	// Set changeable filter
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);

	// Order
	const [sort] = useState<IApiSortBy<TTableData>>({ key: 'id', direction: SortDirectionEnum.DESC });

	// Define columns
	const columns = usePromotionListColumns();

	return (
		<>
			<FilterPlaceholderPortal>
				<PromotionFilter
					values={filter}
					onChange={setFilter}
					controls={
						<ButtonComposition size="sm">
							<ExportPromotionsButton listMetaData={listMetaData} />
							<ExtraFiltersButton onClick={() => promoFilterModalRef.current?.show()} />
							<Can do={promotionsConfig.aclModule} create>
								<LinkButton to={RouteName.PROMOTIONS.CREATE} size="sm">
									{t('actions.create')}
								</LinkButton>
							</Can>
						</ButtonComposition>
					}
					forwardedRef={promoFilterModalRef}
				/>
			</FilterPlaceholderPortal>
			<ApiPaginatedListTable<TTableData, TTableDataFilter>
				columns={columns}
				query={useApiPromotionList}
				useLocation
				sort={sort}
				filter={filter}
				onMetaDataChange={setListMetaData}
			/>
		</>
	);
};

import { Card, CardBody, CardHeader, Toast } from '@avast/react-ui-components';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { FlexibleBillingForm } from 'module/account/forms/FlexibleBillingForm';
import { API_PARTNERS_KEYS, useApiAffiliateUpdate, useApiPartner } from 'module/partners/hooks/useApiPartners';
import { toast } from 'react-toastify';
import { useAsyncInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import { useApiErrorContext, useAuthContext } from 'js/contexts';
import { updateAffiliateApiError } from 'module/partners/utils/apiError';
import { useFeatureFlag } from 'js/hooks/useFeatureFlag';
import { FeatureFlagEnum } from 'config/featureFlags';

export const PaymentMethodCard = () => {
	const { t } = useTranslation(accountConfig.trNamespace);
	const { authCompany, authPartner, setAuthPartner, setAuthCompany } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { data: partner, query } = useApiPartner({
		id: authCompany?.id,
	});
	const { mutateAsync: updateAffiliate } = useApiAffiliateUpdate({
		config: { params: { affiliateId: authCompany?.id } },
	});
	const invalidateQueries = useAsyncInvalidateQueries([API_PARTNERS_KEYS.DETAIL]);
	const creditCardFlag = useFeatureFlag(FeatureFlagEnum.CREDIT_CARD);

	return (
		<Card>
			<CardHeader>
				<div>{t('flexibleBilling.paymentMethod.title')}</div>
				<p className="text-gray fs-md m-0 fw-semibold">{t('flexibleBilling.paymentMethod.subtitle')}</p>
			</CardHeader>
			{(() => {
				if (query.isFetching) {
					return (
						<CardBody>
							<LoadingPlaceholder>{t('common:_.loading')}</LoadingPlaceholder>
						</CardBody>
					);
				}

				return (
					<FlexibleBillingForm
						initialValues={{ paymentMethod: partner?.attributes?.preferredPayAsYouGoPaymentMethod }}
						// Disable auto billing option until it is possible to use credit cards
						autoBillingDisabled={creditCardFlag.isDisabled}
						onSubmit={async (values, formikHelper) => {
							const response = await updateAffiliate({ preferredPayAsYouGoPaymentMethod: values.paymentMethod }).catch(
								(error) => {
									setError({ error, resolve: updateAffiliateApiError });
								},
							);
							await invalidateQueries();

							if (response) {
								if (response.data.id === authPartner?.id) {
									setAuthPartner(response.data);
								} else if (response.data.id === authCompany?.id) {
									setAuthCompany(response.data);
								}
								toast.success(<Toast>{t('flexibleBilling.paymentMethod.success')}</Toast>);
							}

							formikHelper.setSubmitting(false);
						}}
					/>
				);
			})()}
		</Card>
	);
};

import type { IInvalidPartnerState } from 'module/security';
import { RouteName } from 'module/RouteName';
import { isScheduledMaintenance } from 'module/security/utils/maintenance';
import type { TApiErrorList } from 'types/api';
import { API_ERR_CODE } from 'constant';
import { isDefined } from 'js/utils/common';

export const INVALID_PARTNER_STATES: IInvalidPartnerState[] = [
	{
		route: RouteName.SECURITY.MAINTENANCE,
		isInvalid: () => isScheduledMaintenance(),
		force: true,
	},
	{
		route: RouteName.REGISTRATION.DEFAULT,
		isInvalid(context) {
			const { authCompany, isGroupPartner, isLoggedAsUser } = context;
			return isDefined(authCompany) && !authCompany.isRegistered && isGroupPartner && !isLoggedAsUser;
		},
	},
	{
		route: RouteName.SECURITY.REGISTRATION_INCOMPLETE,
		isInvalid(context) {
			const { authCompany, isGroupPartner, isLoggedAsUser } = context;
			return isDefined(authCompany) && !authCompany.isRegistered && isGroupPartner && isLoggedAsUser;
		},
	},
	{
		route: RouteName.REGISTRATION.TERMS,
		isInvalid(context) {
			const { authCompany, isGroupPartner, isLoggedAsUser, isAcceptanceOfTermsSkipped } = context;
			if (!isGroupPartner || isLoggedAsUser) {
				return false;
			}

			if (authCompany.mustAcceptNewTerms) {
				return true;
			}

			if (authCompany.shouldAcceptNewTerms) {
				return !isAcceptanceOfTermsSkipped;
			}

			return false;
		},
	},
	{
		route: RouteName.SECURITY.TERMS,
		isInvalid(context) {
			const { authCompany, isGroupPartner, isLoggedAsUser } = context;
			return Boolean(authCompany?.mustAcceptNewTerms) && isGroupPartner && isLoggedAsUser;
		},
	},
];

export const API_ERROR_PASSWORD_RESET: TApiErrorList = {
	[API_ERR_CODE.PASSWORD_RESET_INVALID_TOKEN]: 'error:api.passwordResetInvalidToken',
	[API_ERR_CODE.PASSWORD_RESET_EXPIRED_TOKEN]: 'error:api.passwordResetExpiredToken',
	[API_ERR_CODE.PASSWORD_ALREADY_USED]: 'error:api.passwordAlreadyUsed',
};

import type { TEntityPromotion } from 'module/promotions';
import { promotionPartyTierEnumUtils, promotionPartyTypeEnumUtils } from 'module/promotions/enums';
import { promotionSchema } from '../schemas';
import { solveCapturedValidationError } from 'js/utils/error';

const promotionEntityNormalizerUtils = {
	normalizePartnerTierList(tiers: TEntityPromotion['partnerTierList']): TEntityPromotion['partnerTierList'] {
		if (!tiers) {
			return tiers;
		}

		return tiers.filter(promotionPartyTierEnumUtils.validateValue);
	},

	normalizePartnerTypeList(types: TEntityPromotion['partnerTypeList']): TEntityPromotion['partnerTypeList'] {
		if (!types) {
			return types;
		}

		return types.filter(promotionPartyTypeEnumUtils.validateValue);
	},
};

export const promotionEntityNormalizer = {
	normalizeList(items: TEntityPromotion[]): TEntityPromotion[] {
		return items.map((item) => promotionEntityNormalizer.normalize(item));
	},

	normalize(promotion: TEntityPromotion) {
		try {
			promotionSchema.parse(promotion);
		} catch (error) {
			solveCapturedValidationError(error, 'promotion', promotion);
		}

		return {
			...promotion,
			partnerTierList: promotionEntityNormalizerUtils.normalizePartnerTierList(promotion.partnerTierList),
			partnerTypeList: promotionEntityNormalizerUtils.normalizePartnerTypeList(promotion.partnerTypeList),
		};
	},
};

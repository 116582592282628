import { type IPageModuleConfig, type IPageModuleLinksConfig, moduleConfigGenerator } from 'module/index';
import { buildParametrizedRoute } from 'js/utils/common';
import type { IEntityPartner } from 'module/partners/index';
import { RouteName } from 'module/RouteName';

export const partnersConfig = moduleConfigGenerator<IPageModuleLinksConfig<IEntityPartner> & IPageModuleConfig>({
	trNamespace: 'modulePartners',
	menuLink: RouteName.PARTNERS.LIST,
	detailLink: (partner) => buildParametrizedRoute(RouteName.PARTNERS.DETAIL, partner.id),
	menuName: 'app:menu.partners',
	aclModule: 'partners',
});

import type { INotification, INotificationListApiFilter } from 'js/notifications';
import { type TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';

export const API_NOTIFICATION_KEYS = {
	LIST: 'notification',
};

export const useApiNotificationList: TUseApiPaginatedListModule<INotification, INotificationListApiFilter> = (
	props,
) => {
	return useApiPaginatedListQuery(API_NOTIFICATION_KEYS.LIST, props);
};

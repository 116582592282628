import { Button } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { TEntityPromotion } from 'module/promotions';
import { PromotionStateEnum } from 'module/promotions/enums';
import { useDisablePromotion } from 'module/promotions/hooks/useDisablePromotion';
import { useAppContext } from 'js/contexts';

export const DisablePromotionButton = () => {
	const {
		data: promotion,
		query: { isFetching },
	} = usePageDetailContext<TEntityPromotion>();
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const { confirmationModalRef } = useAppContext();
	const disablePromotion = useDisablePromotion();

	if (promotion.state === PromotionStateEnum.DISABLED) {
		return null;
	}

	return (
		<Button
			testId="disablePromotion"
			loading={isFetching}
			size="sm"
			variant="outline-danger"
			onClick={async () => {
				const confirmation = await confirmationModalRef.current?.show({
					title: t('actions.disable.confirmation.title'),
					messages: [t('actions.disable.confirmation.message')],
					submitButtonText: t('common:actions.confirm'),
				});

				if (!confirmation) {
					return;
				}

				disablePromotion();
			}}
		>
			{t('actions.disable.action')}
		</Button>
	);
};

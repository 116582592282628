import { useState } from 'react';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { useApiPartnerList } from 'module/partners/hooks/useApiPartners';
import type { IPartnerListFilter } from 'module/partners/index';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';
import { Can } from 'js/components/molecules/Can';
import { customersConfig } from 'module/customers/customersConfig';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { PartnersFilter } from '../components';
import { LinkButton } from 'js/components/atoms/Button';
import { usePartnersListColumns } from '../hooks';
import { useNavigate } from 'react-router';

type TTableDataFilter = IPartnerListFilter;

export const PageList = () => {
	const { t } = useTranslation(partnersConfig.trNamespace);
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);
	const navigate = useNavigate();

	const columns = usePartnersListColumns();

	return (
		<>
			<FilterPlaceholderPortal>
				<PartnersFilter
					values={filter}
					onChange={setFilter}
					controls={
						<Can do={customersConfig.aclModule} create>
							<LinkButton size="sm" variant="primary" to={''} testId="newPartner">
								{t('actions.newPartner')}
							</LinkButton>
						</Can>
					}
				/>
			</FilterPlaceholderPortal>
			<ApiPaginatedListTable
				columns={columns}
				query={useApiPartnerList}
				filter={filter}
				useLocation
				table={{
					meta: {
						onRowClick: (row) => navigate(partnersConfig.detailLink(row)),
					},
				}}
			/>
		</>
	);
};

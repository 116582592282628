import { Badge } from 'react-bootstrap';
import type { PropsWithChildren } from 'react';
import { FormMessage } from '@avast/react-ui-components';

interface ITestFlagProps {
	className?: string;
	badge?: boolean;
}

export const TestFlag = (props: PropsWithChildren<ITestFlagProps>) => {
	const text = props.children || 'Test';

	if (props.badge) {
		return (
			<Badge pill bg="success" className={props.className}>
				{text}
			</Badge>
		);
	}

	return (
		<FormMessage type="success" className="fw-semibold">
			{text}
		</FormMessage>
	);
};

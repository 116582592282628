import { z } from 'zod';
import { LicenseOperationEnum } from 'module/licenses/enums';
import {
	PromotionPartyTierEnum,
	PromotionPartyTypeEnum,
	PromotionScopeEnum,
	PromotionStateEnum,
	PromotionTypeEnum,
} from './enums';
import { MarketSegmentEnum } from 'js/enums';
import { ISO_DATE_REGEX } from 'appConstants';

// General schemas
export const promotionSchema = z.object({
	id: z.number(),
	description: z.string().optional(),
	endDate: z.string().regex(ISO_DATE_REGEX),
	licenseTypeList: z.array(z.nativeEnum(LicenseOperationEnum)).optional(),
	name: z.string(),
	partnerCountryCodeList: z.array(z.string()).optional(),
	partnerId: z.number().nullish(),
	partnerTierList: z.array(z.nativeEnum(PromotionPartyTierEnum)).optional(),
	partnerTypeList: z.array(z.nativeEnum(PromotionPartyTypeEnum)).optional(),
	quantity: z.number().optional(),
	quantityMax: z.number().optional(),
	rate: z.number(),
	scope: z.nativeEnum(PromotionScopeEnum),
	skuList: z.array(z.string()).optional(),
	startDate: z.string().regex(ISO_DATE_REGEX),
	state: z.nativeEnum(PromotionStateEnum),
	type: z.nativeEnum(PromotionTypeEnum),
	marketSegment: z.nativeEnum(MarketSegmentEnum).optional(),
});

// Form schemas
export const createPromotionFormSchema = promotionSchema
	.omit({ startDate: true, endDate: true, state: true, id: true })
	.extend({
		date: z.tuple([z.date().nullable(), z.date().nullable()]).nullable(),
		rate: z.number().optional(),
	});

import { Accordion } from 'react-bootstrap';
import { TermsAccordion } from 'module/registration/components/TermsAccordion';
import { TermsCard } from 'module/registration/components/TermsCard';
import type { IEntityPartnerTerms } from 'module/partners';

type TTermsFormProps = {
	terms: IEntityPartnerTerms[];
};

export const TermsFormContent = (props: TTermsFormProps) => {
	const { terms } = props;

	if (terms.length === 0) {
		return null;
	}

	if (terms.length === 1) {
		return <TermsCard terms={terms[0]} />;
	}

	return (
		<Accordion defaultActiveKey={terms[0].name}>
			{terms.map((terms) => (
				<TermsAccordion key={terms.name} eventKey={terms.name} content={terms.content} />
			))}
		</Accordion>
	);
};

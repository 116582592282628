import { DataTableTr } from 'js/components/molecules/DataTable';
import { DetailList, type TDetailItem } from 'module/promotions/components/DetailList';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { useApiPromotionProductGroupList } from 'module/promotions/hooks/useApiPromotion';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { TEntityPromotion } from 'module/promotions';
import { getProductGroupCodeFromSku, normalizeSku } from 'module/orders/utils/common';
import type { TProductGroupCode } from 'types';

type TProductDetailItem = {
	name: string;
	groupCode: TProductGroupCode;
	skuList: string[];
};

export const ProductsTableRow = () => {
	const maxShownItems = 4;
	const { data: promotion } = usePageDetailContext<TEntityPromotion>();
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const {
		data: productGroups,
		query: { isFetching },
	} = useApiPromotionProductGroupList();

	const values = useMemo((): TDetailItem[] => {
		const productValues: TProductDetailItem[] = [];

		if (typeof promotion.skuList === 'undefined') {
			return productValues;
		}

		for (const sku of promotion.skuList) {
			const groupCode = getProductGroupCodeFromSku(normalizeSku(sku));
			const group = productGroups.find((group) => group.code === groupCode);
			if (!group) {
				continue;
			}

			const productValue = productValues.find((value) => value.groupCode === groupCode);
			if (productValue) {
				productValue.skuList.push(sku);
			} else {
				productValues.push({
					groupCode,
					name: group.name,
					skuList: [sku],
				});
			}
		}

		return productValues.map((productValue) => ({
			name: productValue.name,
			description: productValue.skuList.join(', '),
		}));
	}, [productGroups, promotion.skuList]);

	return (
		<DataTableTr name={t('entity.product', { count: values.length })} isLoading={isFetching} highlighted>
			<DetailList
				title={t('entity.product', { count: values.length })}
				defaultValue={t('placeholder.products')}
				values={values}
				maxShownItems={maxShownItems}
				variant="LINES"
			/>
		</DataTableTr>
	);
};

import { useTranslation } from 'react-i18next';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import type { TEntityPromotion } from 'module/promotions';
import { Row } from 'js/components/atoms/Row';
import { Badge, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import {
	PromotionScopeEnum,
	promotionScopeEnumUtils,
	promotionStateEnumUtils,
	promotionTypeEnumUtils,
} from 'module/promotions/enums';
import { ButtonComposition, H1 } from '@avast/react-ui-components';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { dateFormatter } from 'js/utils/dateTime';
import { numberFormatter } from 'js/utils/number';
import {
	LicenseOperationsRow,
	PartnerRow,
	PartyLocationsRow,
	PartyTierRow,
	PartyTypesRow,
	ProductsTableRow,
	QuantityRow,
} from 'module/promotions/components/detailTable';
import { DisablePromotionButton, EditPromotionButton } from './buttons';
import { Can } from 'js/components/molecules/Can';

export const PromotionDetail = () => {
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const {
		data: promotion,
		query: { isFetching },
	} = usePageDetailContext<TEntityPromotion>();
	const isPartyPromotion = promotion.scope !== PromotionScopeEnum.PRODUCT_ONLY;
	const isProductPromotion = promotion.scope !== PromotionScopeEnum.PARTY_ONLY;

	return (
		<>
			<Row multi justify="between">
				<Col xs="auto">
					<H1 type="h2">{isFetching ? <Skeleton width={320} /> : promotion.name}</H1>
				</Col>
				<Can do={promotionsConfig.aclModule} update>
					<Col xs="auto">
						<ButtonComposition size="sm">
							<DisablePromotionButton />
							<EditPromotionButton />
						</ButtonComposition>
					</Col>
				</Can>
			</Row>
			<Row multi md={2}>
				<Col>
					<DataTable isLoading={isFetching}>
						<DataTableTr name={t('common:entity.description')}>
							{promotion.description ? <em className="fs-md">{promotion.description}</em> : null}
						</DataTableTr>
						<DataTableTr name={t('common:entity.status')}>
							<Badge bg={promotionStateEnumUtils.getColorVariant(promotion.state)}>
								{promotionStateEnumUtils.getText(promotion.state)}
							</Badge>
						</DataTableTr>
						<DataTableTr name={t('common:entity.detailId')}>{promotion.id}</DataTableTr>
						<DataTableTr name={t('entity.scope')}>{promotionScopeEnumUtils.getText(promotion.scope)}</DataTableTr>
						<DataTableTr name={t('common:dates.start')}>{dateFormatter.toDate(promotion.startDate)}</DataTableTr>
						<DataTableTr name={t('common:dates.end')}>{dateFormatter.toDate(promotion.endDate)}</DataTableTr>
						<DataTableTr name={t('entity.type')}>{promotionTypeEnumUtils.getText(promotion.type)}</DataTableTr>
						<DataTableTr name={t('entity.rate')}>{numberFormatter.percentage(promotion.rate)}</DataTableTr>
						{isProductPromotion && <QuantityRow />}
					</DataTable>
				</Col>
				<Col>
					<DataTable isLoading={isFetching}>
						{isPartyPromotion && (
							<>
								<PartnerRow />
								<PartyTierRow />
								<PartyLocationsRow />
								<PartyTypesRow />
							</>
						)}
						{isProductPromotion && (
							<>
								<ProductsTableRow />
								<LicenseOperationsRow />
							</>
						)}
					</DataTable>
				</Col>
			</Row>
		</>
	);
};

export const DEFAULT_ENTITY_VALUE = '–';

// Cache time in milliseconds
export const CACHE_TIME = {
	LOW: 60000, // 1min
	MEDIUM: 600000, // 10min
	HIGH: 3600000, // 60min
	EXTRA_HIGH: 144000000, // 240min
};

export const STATUS_CANCEL = 0;
export const STATUS_SUCCESS = 1;
export const ELLIPSIS_CELL_WIDTH = 300;
export const BUSINESS_BULK_QUANTITY = '*';

export const ISO_DATE_REGEX = /^(\d{4})-([01]\d)-([0-3]\d)T([0-2]\d):([0-5]\d):([0-5]\d)([-+][0-2]\d:[0-5]\d)$/;

import { type ReactElement, useMemo } from 'react';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { RouteName } from 'module/RouteName';
import { Navigate, useNavigate } from 'react-router';
import type { TDetailArguments } from 'types';
import type { TEntityPromotion } from 'module/promotions';
import { PromotionForm } from 'module/promotions/components';
import type { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import { buildParametrizedRoute, isDefined } from 'js/utils/common';
import { useApiPromotion, useApiUpdatePromotion } from 'module/promotions/hooks/useApiPromotion';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { LinkButton } from 'js/components/atoms/Button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { H1, Toast } from '@avast/react-ui-components';
import { Headline } from 'js/components/molecules/Headline';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updatePromotionApiError } from 'module/promotions/utils/apiError';
import { isPromotionEditable } from 'module/promotions/utils/common';
import { promotionFormNormalizer } from 'module/promotions/normalizer';
import { useRouteParams } from 'js/hooks/useRouteParams';
import { useApiErrorContext, useAppContext } from 'js/contexts';

type TArguments = TDetailArguments;
type TData = TEntityPromotion;
type TQueryProps = TUseApiDetailProps<TData, TArguments, TEntityPromotion>;

export const PageUpdate = (): ReactElement | null => {
	const params = useRouteParams<TArguments>();
	const { loadingModalRef } = useAppContext();
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const queryProps: TQueryProps = useMemo(() => ({ filter: params }), [params]);
	const navigate = useNavigate();
	const { setError } = useApiErrorContext();
	const { mutate: updatePromotion } = useApiUpdatePromotion({
		config: { params: { id: Number.parseInt(params?.id) } },
	});

	return (
		<PageDetailComponent<TData>
			query={useApiPromotion}
			trNamespace={promotionsConfig.trNamespace}
			queryProps={queryProps}
			titleRender={() => t('page.update.title', { id: params.id })}
			detailLink={buildParametrizedRoute(RouteName.PROMOTIONS.UPDATE, params.id)}
		>
			{({ data }) => {
				const detailLink = promotionsConfig.detailLink(data);

				if (!isPromotionEditable(data)) {
					toast.error(<Toast>{t('page.update.notEditable')}</Toast>);
					return <Navigate to={detailLink} replace />;
				}

				return (
					<>
						<Headline
							className="align-items-center"
							rightContent={
								<LinkButton
									testId="back"
									size="sm"
									variant="outline-primary"
									to={detailLink}
									iconLeftFa={faChevronLeft}
								>
									{t('common:actions.back')}
								</LinkButton>
							}
						>
							<H1 type="h2">{data.name}</H1>
						</Headline>
						<div className="section__content mt-3">
							<PromotionForm
								isUpdate
								onCancel={() => navigate(detailLink)}
								initialValues={promotionFormNormalizer.normalize(data)}
								onSubmit={(values, { setSubmitting }) => {
									loadingModalRef.current?.show({ title: t('common.updatingPromotion') });
									updatePromotion(promotionFormNormalizer.denormalize(values), {
										onSuccess(response) {
											setSubmitting(false);
											loadingModalRef.current?.hide();
											if (isDefined(response?.data)) {
												toast.success(<Toast>{t('page.update.success')}</Toast>);
												navigate(promotionsConfig.detailLink(response.data));
											}
										},
										onError(error) {
											loadingModalRef.current?.hide();
											setError({ error, resolve: updatePromotionApiError });
											setSubmitting(false);
										},
									});
								}}
							/>
						</div>
					</>
				);
			}}
		</PageDetailComponent>
	);
};

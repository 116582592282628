import { useCallback, useState } from 'react';
import type { IEntityPartner, IEntityPartnerDetail, IPartnerListFilter } from 'module/partners';
import { type PartnerSubTypeEnum, PartnerTypeEnum, PartnerStatusEnum } from 'module/partners/enums';
import { SelectPartnerModalFilter } from 'module/partners/components/SelectPartnerModalFilter';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { useApiPartnerList } from 'module/partners/hooks/useApiPartners';
import { useAsyncPartnerDetail } from 'module/partners/hooks/useAsyncPartnerDetail';
import { useSelectPartnerColumns } from 'module/partners/hooks';

export type TSelectPartnerContainerProps = {
	selectedPartnerId?: IEntityPartner['id'];
	accountTypesWhiteList?: PartnerTypeEnum[];
	accountSubTypesWhiteList?: PartnerSubTypeEnum[];
	detailed?: boolean; // Load and return partner detail
	staticFilter?: IPartnerListFilter;
};

type TTableData = IEntityPartnerDetail;
type TTableFilter = IPartnerListFilter;

export const SelectPartnerContainer = (
	props: TSelectPartnerContainerProps & {
		onSelect(partner: IEntityPartner): void;
	},
) => {
	const {
		selectedPartnerId,
		onSelect,
		accountTypesWhiteList = [PartnerTypeEnum.DISTRIBUTOR, PartnerTypeEnum.RESELLER],
		accountSubTypesWhiteList,
		detailed,
		staticFilter,
	} = props;
	const getAsyncPartnerDetail = useAsyncPartnerDetail();

	// States
	const [dataFilter, setDataFilter] = useState<TTableFilter | null>({
		accountTypes: accountTypesWhiteList,
		accountSubTypes: accountSubTypesWhiteList?.length === 1 ? accountSubTypesWhiteList : undefined,
		...staticFilter,
	});

	const onSelectHandler = useCallback(
		async (partner: IEntityPartner) => {
			if (detailed) {
				const partnerDetail = await getAsyncPartnerDetail(partner.id);
				if (partnerDetail) {
					onSelect(partnerDetail);
				}
			} else {
				onSelect(partner);
			}
		},
		[detailed, onSelect, getAsyncPartnerDetail],
	);

	// Define columns
	const columns = useSelectPartnerColumns();

	return (
		<>
			<SelectPartnerModalFilter
				className="mb-3"
				values={dataFilter}
				onChange={setDataFilter}
				accountTypes={accountTypesWhiteList}
				accountSubTypes={accountSubTypesWhiteList}
			/>

			<ApiPaginatedListTable<TTableData, TTableFilter>
				columns={columns}
				query={useApiPartnerList}
				filter={dataFilter}
				filterStatic={{ status: PartnerStatusEnum.ACTIVE }}
				limit={8}
				table={{
					testId: 'selectPartner',
					initialState: { sorting: [{ id: 'companyName', desc: false }] },
					meta: {
						nowrapCell: false,
						onRowClick: (row) => onSelectHandler(row),
						isHighlightedRow: (row) => Boolean(selectedPartnerId) && row.id === selectedPartnerId,
						paginationComponent: 'Basic',
					},
				}}
			/>
		</>
	);
};

import { DetailList, type TDetailItem } from 'module/promotions/components/DetailList';
import { DataTableTr } from 'js/components/molecules/DataTable';
import { useMemo } from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { TEntityPromotion } from 'module/promotions';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { useApiCountryList } from 'js/entities/country/useApiCountry';

export const PartyLocationsRow = () => {
	const { data: promotion } = usePageDetailContext<TEntityPromotion>();
	const { t } = useTranslation(promotionsConfig.trNamespace);

	const {
		data: countries,
		query: { isFetching },
	} = useApiCountryList({
		filter: { enabled: null },
	});

	const values = useMemo(
		() =>
			(promotion.partnerCountryCodeList
				?.map((promotionCountryCode) => {
					const country = countries.find((countryCode) => countryCode.code === promotionCountryCode);
					return country ? { name: country.name, description: country.code } : null;
				})
				.filter((n) => Boolean(n)) as TDetailItem[]) || [],
		[countries, promotion.partnerCountryCodeList],
	);

	return (
		<DataTableTr name={t('entity.partyCountry', { count: values.length })} isLoading={isFetching}>
			<DetailList
				title={t('entity.partyCountry', { count: values.length })}
				defaultValue={t('placeholder.partyCountries')}
				values={values}
				maxShownItems={4}
			/>
		</DataTableTr>
	);
};

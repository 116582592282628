import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import type { IEntityPartner } from 'module/partners/index';
import { partnersConfig } from 'module/partners/partnersConfig';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';
import { PartnerStatusEnum, partnerStatusEnumUtils, partnerTypeEnumUtils } from 'module/partners/enums';
import { useTranslation } from 'react-i18next';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';

type TTableData = IEntityPartner;

export const usePartnersListColumns = () => {
	const { t } = useTranslation(partnersConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.text('id', {
				header: t('common:entity.id'),
			}),
			columnHelper.ellipsis((row) => getPartnerRenderName(row), {
				enableSorting: false,
				header: t('common:entity.partner'),
				meta: {
					formatters: [formatter.bold],
				},
			}),
			columnHelper.copy2clipboard('email', {
				header: t('common:contact.email'),
			}),
			// TODO: https://butr.avast.com/browse/BOSS-8934 Currently the endpoint doesnt return the date
			// columnHelper.date((row) => row.date, {
			// 	header: t('common:dates.created'),
			// 	meta: {
			// 		defaultValue: { value: noValuePlaceholder(t('common:_.NA')) },
			// 	},
			// }),
			columnHelper.text('status', {
				header: t('common:entity.status'),
				enableSorting: false,
				meta: {
					formatters: [
						(value: PartnerStatusEnum) => {
							const statusClasses = {
								[PartnerStatusEnum.ACTIVE]: 'text-success',
								[PartnerStatusEnum.INACTIVE]: 'text-danger',
								[PartnerStatusEnum.DENIED]: 'text-warning',
								[PartnerStatusEnum.PENDING]: 'text-warning',
							};

							const className = statusClasses[value];
							return <span className={className}>{partnerStatusEnumUtils.getText(value)}</span>;
						},
					],
				},
			}),
			columnHelper.text('accountType', {
				header: t('entity.accountType'),
				enableSorting: false,
				meta: {
					formatters: [partnerTypeEnumUtils.getText],
				},
			}),
			columnHelper.actions({
				link: {
					children: t('common:actions.detail'),
					to: partnersConfig.detailLink,
				},
			}),
		];
	}, [t]);
};

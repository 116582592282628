import type { ReactElement } from 'react';
import { resolveTermsAcceptedApiError } from 'module/registration/utils/apiError';
import { TermsForm } from 'module/registration/forms/TermsForm';
import { RouteName } from 'module/RouteName';
import { Navigate, useNavigate } from 'react-router';
import { useApiAcceptUpdatedTerms } from 'module/partners/hooks/useApiPartners';
import { Toast } from '@avast/react-ui-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { useApiErrorContext, useAuthContext } from 'js/contexts';
import { useLocationState } from 'js/hooks/useLocationState';
import type { ITermsPageState } from 'module/registration';
import { navigateLinkToNavigateObject } from 'js/utils/link';

export const PageTerms = (): ReactElement => {
	const { setError } = useApiErrorContext();
	const { authPartner, setAuthPartner, setIsAcceptanceOfTermsSkipped } = useAuthContext();
	const { t } = useTranslation(registrationConfig.trNamespace);
	const navigate = useNavigate();
	const { mutate: acceptTerms } = useApiAcceptUpdatedTerms({
		config: { params: { partnerId: authPartner?.id } },
	});
	const { previousLocation } = useLocationState<ITermsPageState>();

	if (!authPartner?.shouldAcceptNewTerms) {
		return <Navigate to={RouteName.HOMEPAGE.DEFAULT} replace />;
	}

	if (!authPartner?.id) {
		return <Navigate to={RouteName.SECURITY.LOGOUT} replace />;
	}

	return (
		<div className="pt-10 pb-3">
			<TermsForm
				partnerId={authPartner.id}
				onSkip={() => {
					setIsAcceptanceOfTermsSkipped(true);
					const navigateObject = navigateLinkToNavigateObject(previousLocation || RouteName.HOMEPAGE.DEFAULT);
					navigate(navigateObject.to, navigateObject.options);
				}}
				onSubmit={(values, { setSubmitting }) => {
					acceptTerms(
						{},
						{
							onSuccess(response) {
								toast.success(<Toast>{t('common.successAcceptTerms')}</Toast>);
								setAuthPartner(response.data);
								navigate(RouteName.HOMEPAGE.DEFAULT);
							},
							onError(error) {
								setError({ error, resolve: resolveTermsAcceptedApiError });
								setSubmitting(false);
							},
						},
					);
				}}
			/>
		</div>
	);
};

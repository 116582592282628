import { SelectPartyEnum, SelectPartyOptionEnum } from '../enums';
import type { PropsWithChildren } from 'react';
import { useSelectPartyTranslation } from '../hooks';
import { SelectPartyCard, SelectPartyCardBody, SelectPartyCardFooter } from '../common';
import { Alert } from '@avast/react-ui-components';
import type { TSelectPartnerModalContentResolution, TSelectPartyModalContentProps } from './types';
import { isDefined } from 'js/utils/common';
import type { IEntityPartnerDetail } from 'module/partners';
import { PartnerCard } from 'module/partners/components';
import { useOrderContext } from 'js/contexts';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import type { TPartnerPriceLists } from 'js/priceList';

type TSelectCustomerCardProps = TSelectPartyModalContentProps<TSelectPartnerModalContentResolution> & {
	option: SelectPartyOptionEnum;
	partner?: IEntityPartnerDetail | null;
	partnerPriceLists?: TPartnerPriceLists | null;
	isNotAllowed?: boolean;
};

export const SelectPartnerCard = (props: PropsWithChildren<TSelectCustomerCardProps>) => {
	const { partner, partnerPriceLists, onResolve, isNotAllowed } = props;
	const { tParty, tPartyOption } = useSelectPartyTranslation(SelectPartyEnum.PARTNER);
	const { orderState } = useOrderContext();
	const licenseOperationContext = useLicenseOperationContext();

	const isEmptyAllowed = licenseOperationContext.customer || isDefined(orderState.customer);
	const isBtnDisabled = isNotAllowed || (!isEmptyAllowed && !partner);

	return (
		<SelectPartyCard type={SelectPartyEnum.PARTNER} option={props.option}>
			<SelectPartyCardBody>
				{(() => {
					if (!partner) {
						return (
							<>
								<Alert variant="warning">{tPartyOption(props.option, 'empty')}</Alert>
								<Alert variant={isEmptyAllowed ? 'info' : 'danger'}>
									{tParty(isEmptyAllowed ? 'emptyBillableParty' : 'requiredBillableParty')}
								</Alert>
							</>
						);
					}

					return (
						<>
							<PartnerCard partner={partner} />
							{isNotAllowed ? (
								<Alert className="mt-3 mb-0" variant="danger">
									{tPartyOption(props.option, 'notAllowed')}
								</Alert>
							) : (
								<Alert className="mt-3 mb-0">{tParty('billableParty')}</Alert>
							)}
						</>
					);
				})()}
			</SelectPartyCardBody>

			<SelectPartyCardFooter
				option={props.option}
				disabled={isBtnDisabled}
				onSelect={() => onResolve({ partner, partnerPriceLists })}
			>
				{tPartyOption(props.option, partner || props.option === SelectPartyOptionEnum.ORDER ? 'button' : 'buttonEmpty')}
			</SelectPartyCardFooter>
		</SelectPartyCard>
	);
};

import type { IEntityOrder } from 'module/orders';
import { useApiProductGroupList } from 'module/purchase/hooks/useApiPurchase';
import { CONFIG } from 'config';
import { getProductGroupCodeFromSku } from 'module/orders/utils/common';
import { ProductBrandEnum } from 'module/purchase/enums';

type TUseAvastCheckoutUrl = { checkoutUrl: string; isLoading?: boolean };

export const useAvastCheckoutUrl = (order: IEntityOrder): TUseAvastCheckoutUrl => {
	const { data: productGroupList, query } = useApiProductGroupList({
		filter: {
			countryCode: order.billableParty.countryCode,
			currencyCode: order.currency,
			priceListCode: order.priceListCode,
		},
	});

	const hasAvgProducts = order.lineItems.some((lineItem) => {
		const productGroupCode = getProductGroupCodeFromSku(lineItem.product.sku);
		const productGroup = productGroupList.find((group) => group.code === productGroupCode);
		return productGroup?.brand === ProductBrandEnum.AVG;
	});

	return {
		checkoutUrl: hasAvgProducts ? CONFIG.APP.AVG_CHECKOUT_URL : CONFIG.APP.AVAST_CHECKOUT_URL,
		isLoading: query.isPending,
	};
};

import { DetailList } from 'module/promotions/components/DetailList';
import { DataTableTr } from 'js/components/molecules/DataTable';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import type { TEntityPromotion } from 'module/promotions';
import { licenseOperationEnumUtils } from 'module/licenses/enums';

export const LicenseOperationsRow = () => {
	const { data: promotion } = usePageDetailContext<TEntityPromotion>();
	const { t } = useTranslation(promotionsConfig.trNamespace);
	const values =
		promotion.licenseTypeList?.map((licenseOperation) => licenseOperationEnumUtils.getText(licenseOperation)) || [];

	return (
		<DataTableTr name={t('entity.licenseOperation', { count: values.length })}>
			<DetailList
				title={t('entity.licenseOperation', { count: values.length })}
				defaultValue={t('placeholder.licenseOperations')}
				values={values}
				maxShownItems={4}
			/>
		</DataTableTr>
	);
};

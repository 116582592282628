import { type Dispatch, useCallback } from 'react';
import type { IStandardOrderInstance } from 'module/purchase';
import { isOrderInstanceObsolete, logDebug } from 'js/utils/app';
import type { IAuthData } from 'js/reducers/authContextReducer';
import type { IOrderReducerSetPartnerPayload, TOrderStateAction } from 'js/reducers/orderReducer';
import { useRefreshOrderPartnerState } from 'js/hooks/useRefreshOrderPartnerState';
import { useAsyncApiCustomer, useAsyncApiPartnerCustomer } from 'module/customers/hooks/useAsyncApiCustomers';
import { isEqual } from 'lodash';
import { useAsyncApiDistributionPartner } from 'module/distributionPartners/hooks/useAsyncApiDistributionPartners';
import { useIsRefreshRestricted } from 'js/hooks/useIsRefreshRestricted';
import invariant from 'invariant';
import { isDefined } from 'js/utils/common';
import { logParsedError } from 'js/utils/error';

export interface IRefreshOrderStateProps {
	state: IStandardOrderInstance;
	authData?: IAuthData;
	dispatch: Dispatch<TOrderStateAction>;
}

export const useRefreshOrderState = () => {
	const refreshOrderPartnerState = useRefreshOrderPartnerState();
	const asyncApiCustomer = useAsyncApiCustomer();
	const asyncApiPartnerCustomer = useAsyncApiPartnerCustomer();
	const asyncApiDistributionPartner = useAsyncApiDistributionPartner();
	const isRefreshRestricted = useIsRefreshRestricted();

	return useCallback(
		async (props: IRefreshOrderStateProps): Promise<void> => {
			const { state, dispatch, authData } = props;

			// Do not run when is restricted
			if (isRefreshRestricted) {
				return;
			}

			// Logged partner has different ID then state owner
			if (isOrderInstanceObsolete(state, authData?.partner?.id)) {
				return;
			}

			// Partner Order
			if (state.partner) {
				logDebug('useRefreshOrderState', 'PARTNER');
				let partnerPayload: IOrderReducerSetPartnerPayload;

				try {
					partnerPayload = await refreshOrderPartnerState({ state, dispatch, authData });
				} catch (error) {
					logParsedError({
						message: 'Getting partner data from the partner order has failed.',
						error: error,
					});

					// If there is no partner data on the partner order, remove all affiliates
					dispatch({ type: 'REMOVE_PARTNER' });
					dispatch({ type: 'SET_CUSTOMER', payload: null });
					dispatch({ type: 'SET_DISTRIBUTION_PARTNER', payload: null });
					return;
				}

				// Partner customer
				if (state.customer) {
					logDebug('useRefreshOrderState', 'PARTNER CUSTOMER');
					const partnerId = partnerPayload.partner.id;
					const customerId = state.customer.id;

					try {
						invariant(partnerId !== null, 'Partner on the order has no id number');
						invariant(isDefined(customerId), 'Customer on the order has no id number');
						const customerResponse = await asyncApiPartnerCustomer(
							{
								partnerId: partnerId,
								salesforceId: customerId,
							},
							{ authToken: authData?.token },
						);

						if (!isEqual(customerResponse.data, state.customer)) {
							dispatch({ type: 'SET_CUSTOMER', payload: customerResponse.data });
						}
					} catch (error) {
						logParsedError({
							message: 'Getting customer data from the partner order has failed.',
							error: error,
							context: { partnerId, customerId },
						});
						dispatch({ type: 'SET_CUSTOMER', payload: null });
					}
				}

				// Partner distribution partner
				if (state.distributionPartner) {
					logDebug('useRefreshOrderState', 'DISTRIBUTION PARTNER');
					const distributorId = partnerPayload.partner.id; // Partner is distributor
					const distributionPartnerId = state.distributionPartner.id;

					try {
						const distributionPartnerResponse = await asyncApiDistributionPartner(
							{
								distributorId: distributorId,
								id: distributionPartnerId,
							},
							{ authToken: authData?.token },
						);

						if (!isEqual(distributionPartnerResponse.data, state.distributionPartner)) {
							dispatch({ type: 'SET_DISTRIBUTION_PARTNER', payload: distributionPartnerResponse.data });
						}
					} catch (error) {
						logParsedError({
							message: 'Getting distribution partner data from the partner order has failed.',
							error: error,
							context: { distributorId, distributionPartnerId },
						});
						dispatch({ type: 'SET_DISTRIBUTION_PARTNER', payload: null });
					}
				}
			}

			// Customer Order
			else if (state.customer) {
				logDebug('useRefreshOrderState', 'CUSTOMER');
				const customerId = state.customer.id;

				try {
					invariant(isDefined(customerId), 'Customer on the order has no id number');

					const customerResponse = await asyncApiCustomer({ salesforceId: customerId }, { authToken: authData?.token });
					if (!isEqual(customerResponse.data, state.customer)) {
						dispatch({ type: 'SET_CUSTOMER', payload: customerResponse.data });
					}
				} catch (error) {
					logParsedError({
						message: 'Getting customer data from the end customer order has failed.',
						error: error,
						context: { customerId },
					});
					dispatch({ type: 'SET_CUSTOMER', payload: null });
				}
			}
		},
		[
			refreshOrderPartnerState,
			asyncApiPartnerCustomer,
			asyncApiCustomer,
			asyncApiDistributionPartner,
			isRefreshRestricted,
		],
	);
};

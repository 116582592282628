import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { Route, Routes } from 'react-router';
import { accountConfig } from 'module/account/accountConfig';
import { PageAccount, PageFlexibleBilling, PageTaxExemption } from 'module/account/pages';
import { FlexibleBillingLayout } from 'module/account/layouts/FlexibleBillingLayout';

export const AccountRoutes = () => {
	const { aclModule, flexibleBilling } = accountConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ]} />}>
				<Route index element={<PageAccount />} />
			</Route>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ]} />}>
				<Route path="tax-exemption" element={<PageTaxExemption />} />
			</Route>
			<Route element={<FlexibleBillingLayout />}>
				<Route element={<AuthRoute acl={[aclModule, ACL_READ]} authGuards={flexibleBilling.authGuards} />}>
					<Route path="flexible-billing" element={<PageFlexibleBilling />} />
				</Route>
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};

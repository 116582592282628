import { Modal, Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { CustomerForm } from 'module/customers/forms/CustomerForm';
import { toast } from 'react-toastify';
import { createCustomerApiError } from 'module/customers/utils/apiError';
import { useApiCreateCustomer } from 'module/customers/hooks/useApiCustomers';
import type { ICustomerForm, IEntityCustomer } from 'module/customers';
import { useApiErrorContext } from 'js/contexts';
import type { TPartnerId } from 'types';

type TCreateCustomerContainerProps = {
	initialValues?: ICustomerForm;
	partnerId?: TPartnerId | null;
	onCreate(customer: IEntityCustomer): void;
	onCancel(): void;
};

export const CreateCustomerContainer = (props: TCreateCustomerContainerProps) => {
	const { t } = useTranslation(customersConfig.trNamespace);
	const { setError } = useApiErrorContext();
	const { mutateAsync: createCustomer } = useApiCreateCustomer();

	return (
		<>
			<Modal.Header>{t('page.create.title')}</Modal.Header>
			<Modal.Body>
				<CustomerForm
					initialValues={props.initialValues}
					partnerId={props.partnerId}
					onSubmit={async (request, { setSubmitting }) => {
						const response = await createCustomer({
							data: request.customer,
							params: { partnerId: request.partnerId },
						}).catch((error) => {
							setError({ error, resolve: createCustomerApiError });
						});

						setSubmitting(false);
						if (response?.data) {
							toast.success(<Toast>{t('page.create.success')}</Toast>);
							props.onCreate(response.data);
						}
					}}
					onCancel={() => {
						props.onCancel();
					}}
				/>
			</Modal.Body>
		</>
	);
};

import { useTranslation } from 'react-i18next';
import { accountConfig } from 'module/account/accountConfig';
import { Alert, Card, CardBody, CardHeader, Modal, type TCardProps, UiTable } from '@avast/react-ui-components';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { useApiPartnerAcceptedTermsList } from 'module/partners/hooks/useApiPartners';
import type { IEntityPartnerTerms } from 'module/partners';
import { partnersConfig } from 'module/partners/partnersConfig';
import { partnerTermsEnumUtils } from 'module/partners/enums';
import { useTermsCardColumns } from 'module/account/hooks';
import { useAuthContext } from 'js/contexts';

export const TermsCard = (props: Partial<TCardProps>) => {
	const { authCompany: company } = useAuthContext();
	const { t } = useTranslation(accountConfig.trNamespace);
	const { t: tPartner } = useTranslation(partnersConfig.trNamespace);
	const { data: terms, query } = useApiPartnerAcceptedTermsList({ filter: { partnerId: company?.id } });
	const ref = useAsyncModalRef<IEntityPartnerTerms>();
	const columns = useTermsCardColumns(ref);

	if (!company) {
		return null;
	}

	const hasError = query.isError || (!query.isFetching && !terms);
	return (
		<Card {...props}>
			<CardHeader>{t('cards.terms')}</CardHeader>
			<CardBody noSpacing={!hasError} className="card-body--table">
				{(() => {
					if (hasError) {
						return <Alert variant="warning" caption={tPartner('error.unableToLoadTerms')} />;
					}

					return (
						<UiTable<IEntityPartnerTerms>
							columns={columns}
							data={terms}
							testId="terms"
							meta={{
								loading: query.isFetching,
								hideHeader: true,
								rowCount: 2,
							}}
						/>
					);
				})()}
				<AsyncModal<IEntityPartnerTerms> ref={ref} size="lg" testId="termsDetail">
					{({ content, name }) => (
						<>
							<Modal.Header>{partnerTermsEnumUtils.getText(name)}</Modal.Header>
							<Modal.Body>
								<div
									className="fs-md p-3 border bg-light rounded"
									style={{ overflowY: 'auto', maxHeight: '500px' }}
									// biome-ignore lint/security/noDangerouslySetInnerHtml: Our own content is passed.
									dangerouslySetInnerHTML={{ __html: content }}
								/>
							</Modal.Body>
							<Modal.Footer cancelButton />
						</>
					)}
				</AsyncModal>
			</CardBody>
		</Card>
	);
};

import type { ReactElement } from 'react';
import { Alert } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'js/components/atoms/Row';
import {
	AccountInformationCard,
	AddressInformationCard,
	ContactInformationCard,
	PersonalizationCard,
	TermsCard,
} from 'module/account/components/cards';
import { useAuthContext } from 'js/contexts';

export const PageAccount = (): ReactElement | null => {
	const { t } = useTranslation();
	const { authPartner, isGroupPartner } = useAuthContext();

	if (authPartner === null) {
		return <Alert caption={t('error:sectionDataNotFound')} />;
	}

	if (isGroupPartner) {
		return (
			<Row multi size="md">
				<Col md="6">
					<AccountInformationCard />
					<ContactInformationCard cardConfig={{ className: 'mt-4' }} />
					<AddressInformationCard cardConfig={{ className: 'mt-4' }} />
				</Col>
				<Col md="6">
					<PersonalizationCard cardConfig={{ className: 'mb-4' }} />
					<TermsCard />
				</Col>
			</Row>
		);
	}

	return (
		<Row multi size="md">
			<Col md="6">
				<ContactInformationCard />
			</Col>
			<Col md="6">
				<PersonalizationCard />
			</Col>
		</Row>
	);
};

import { Card, CardBody } from '@avast/react-ui-components';
import type { IEntityPartnerTerms } from 'module/partners';

type TTermsCardProps = {
	terms: IEntityPartnerTerms;
};

export const TermsCard = (props: TTermsCardProps) => {
	const { terms } = props;

	return (
		<Card className="rounded-2">
			<CardBody className="p-0">
				<div
					className="terms-content-container p-3 fs-sm"
					// biome-ignore lint/security/noDangerouslySetInnerHtml: Content that is under our control is passed.
					dangerouslySetInnerHTML={{ __html: terms.content }}
				/>
			</CardBody>
		</Card>
	);
};

import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { partnersConfig } from 'module/partners/partnersConfig';
import { PartnersRoutes } from '../PartnersRoutes';

export const PartnersContainer = () => {
	return (
		<DefaultContainer>
			<ModuleContainer moduleMenuConfig={partnersConfig} />
			<PartnersRoutes />
		</DefaultContainer>
	);
};

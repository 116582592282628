import type { ReactElement } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { ButtonComposition } from '@avast/react-ui-components';
import { InformationTable } from 'module/closingBalance/components';
import { OrderDocumentsButton, ViewRefundsButton } from 'module/closingBalance/components/buttons';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { InitiateRefundButton } from 'submodule/refunds/components/InitiateRefundButton';
import { usePageDetailContext } from 'js/contexts';
import type { IEntityClosingBalance } from 'module/closingBalance/index';
import { TestFlag } from 'js/components/common';

export const InformationTab = (): ReactElement => {
	const viewRefundsModalRef = useAsyncModalRef();
	const { data } = usePageDetailContext<IEntityClosingBalance>();

	function showClosingBalanceRefunds() {
		viewRefundsModalRef.current?.show();
	}

	return (
		<DefaultContainer>
			<ButtonComposition marginY wrap>
				<InitiateRefundButton showRefundsModal={showClosingBalanceRefunds} />
				<ViewRefundsButton forwardedRef={viewRefundsModalRef} />
				<OrderDocumentsButton />
			</ButtonComposition>
			<div className="hstack gap-2">{data.flagTest && <TestFlag badge className="mt-2" />}</div>
			<InformationTable />
		</DefaultContainer>
	);
};

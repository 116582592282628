import { Col, Row } from 'js/components/atoms/Row';
import { DataFilter } from 'js/components/molecules/DataFilter';
import { SearchComboBox } from 'js/components/molecules/SearchBox';
import { FormControl } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { partnersConfig } from 'module/partners/partnersConfig';
import { type PartnerStatusEnum, partnerStatusEnumUtils, PartnerTypeEnum, partnerTypeEnumUtils } from '../enums';
import type { IPartnerListFilter } from 'module/partners/index';
import type { ReactNode } from 'react';
import type { TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';

type TFilter = IPartnerListFilter;
type TFilterProps = {
	controls?: ReactNode;
};

export const PartnersFilter = (props: TDataFilterProps<IPartnerListFilter> & TFilterProps) => {
	const { controls } = props;
	const { t } = useTranslation(partnersConfig.trNamespace);

	return (
		<DataFilter<TFilter> useLocation values={props.values} onChange={props.onChange}>
			{({ values, isEnabledField, updateFilter }) => (
				<>
					<Row multi size="sm">
						<Col lg={4} md={8} sm={6}>
							<SearchComboBox
								name="search"
								placeholder={t('select.filter.placeholder')}
								onCancel={() => updateFilter({ search: '' })}
								onSubmit={({ value }) => updateFilter({ search: value })}
							/>
						</Col>
						<Col lg={8} md={4} sm={6} className="text-end">
							{controls}
						</Col>
					</Row>
					<Row multi size="sm">
						<Col lg={3} md={4} sm={6}>
							<FormControl label={t('common:entity.status')}>
								<FormControl.SingleSelect<PartnerStatusEnum>
									name="status"
									value={values.status}
									onChange={(value) => updateFilter(value ? { status: value } : { status: undefined })}
									size="sm"
									disabled={!isEnabledField('status')}
									placeholder={t('components:select.statusPlaceholder')}
									options={partnerStatusEnumUtils.getSelectOptions()}
								/>
							</FormControl>
						</Col>
						<Col lg={3} md={4} sm={6}>
							<FormControl label={t('entity.accountType')}>
								<FormControl.MultiSelect<PartnerTypeEnum>
									name="accountTypes"
									value={values.accountTypes ? values.accountTypes : []}
									onChange={(value) => updateFilter({ accountTypes: value || undefined })}
									size="sm"
									disabled={!isEnabledField('accountTypes')}
									placeholder={t('components:select.typePlaceholder')}
									closeMenuOnSelect={false}
									options={partnerTypeEnumUtils.getSelectOptions({ omitted: [PartnerTypeEnum.INTERNAL_COMPANY] })}
								/>
							</FormControl>
						</Col>
					</Row>
				</>
			)}
		</DataFilter>
	);
};

import type { FunctionComponent } from 'react';
import { Menu } from 'js/layouts/Menu';
import { RouteName } from 'module/RouteName';
import {
	IconArrowDoubleLeft,
	IconCart,
	IconClosingBalance,
	IconCreditLimit,
	IconCreditStatus,
	IconCustomer,
	IconDistributionPartner,
	IconDocument,
	IconFeedback,
	IconFlexibleBilling,
	IconHelp,
	IconHome,
	IconLicenses,
	IconPartners,
	IconPriceCalculator,
	IconPromotions,
	IconQuotes,
	IconRetailCodes,
} from 'assets/image/icon';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { ordersConfig } from 'module/orders/ordersConfig';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { retailConfig } from 'module/retail/retailConfig';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { customersConfig } from 'module/customers/customersConfig';
import { Button, TestIdContextProvider } from '@avast/react-ui-components';
import { CONFIG } from 'config';
import { creditLimitConfig } from 'module/creditLimit/creditLimitConfig';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { useAppContext, useAuthContext } from 'js/contexts';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { partnersConfig } from 'module/partners/partnersConfig';

export const AppSidebar: FunctionComponent = () => {
	const { t } = useTranslation('app');
	const { isGroupPartner, isAuthorizedBuyer } = useAuthContext();
	const itemClass = 'sidebar-padding-x';
	const { sidebarCollapsed, helpModalRef } = useAppContext();

	return (
		<TestIdContextProvider prefix="mainMenu">
			<div className="sidebar-section">
				<Menu.List className="sidebar-menu sidebar-mainMenu">
					<Menu.Group>
						<Menu.Item
							icon={IconHome}
							to={RouteName.HOMEPAGE.DEFAULT}
							className={itemClass}
							end
							text={t('menu.dashboard')}
							testId="homepage"
						/>
						<Menu.Item
							icon={IconCustomer}
							to={customersConfig.menuLink}
							className={itemClass}
							text={t(customersConfig.menuName)}
							acl={customersConfig.aclModule}
							isAllowed={isGroupPartner}
							testId="customers"
						/>
						<Menu.Item
							icon={IconDistributionPartner}
							to={distributionPartnersConfig.menuLink}
							className={itemClass}
							text={t(distributionPartnersConfig.menuName)}
							acl={distributionPartnersConfig.aclModule}
							isAllowed={isGroupPartner}
							testId="distributionPartners"
						/>
					</Menu.Group>
					<Menu.Group>
						<Menu.Item
							icon={IconCart}
							to={purchaseConfig.menuLink}
							className={itemClass}
							text={t(purchaseConfig.menuName)}
							acl={purchaseConfig.aclModule}
							isAllowed={isAuthorizedBuyer}
							testId="purchase"
						/>
						<Menu.Item
							icon={IconFlexibleBilling}
							to={flexibleBillingConfig.menuLink}
							className={itemClass}
							text={t(flexibleBillingConfig.menuName)}
							acl={flexibleBillingConfig.aclModule}
							testId="flexibleBilling"
						/>
						<Menu.Item
							icon={IconLicenses}
							to={licensesConfig.menuLink}
							className={itemClass}
							text={t(licensesConfig.menuName)}
							acl={licensesConfig.aclModule}
							testId="licenses"
						/>
						<Menu.Item
							icon={IconRetailCodes}
							to={retailConfig.menuLink}
							className={itemClass}
							text={t(retailConfig.menuName)}
							acl={retailConfig.aclModule}
							authGuards={retailConfig.authGuards}
							testId="retail"
						/>
					</Menu.Group>
					<Menu.Group>
						<Menu.Item
							icon={IconDocument}
							to={ordersConfig.menuLink}
							className={itemClass}
							text={t(ordersConfig.menuName)}
							acl={ordersConfig.aclModule}
							testId="orders"
						/>
						<Menu.Item
							icon={IconClosingBalance}
							to={closingBalanceConfig.menuLink}
							className={itemClass}
							text={t(closingBalanceConfig.menuName)}
							acl={closingBalanceConfig.aclModule}
							authGuards={closingBalanceConfig.authGuards}
							testId="closingBalance"
						/>
						<Menu.Item
							icon={IconQuotes}
							to={ordersConfig.quotes.menuLink}
							className={itemClass}
							text={t(ordersConfig.quotes.menuName)}
							acl={ordersConfig.quotes.aclModule}
							testId="quotes"
						/>
					</Menu.Group>
					<Menu.Group>
						<Menu.Item
							icon={IconCreditStatus}
							to={creditStatusConfig.menuLink}
							className={itemClass}
							text={t(creditStatusConfig.menuName)}
							acl={creditStatusConfig.aclModule}
							authGuards={creditStatusConfig.authGuards}
							testId="creditStatus"
						/>
						<Menu.Item
							icon={IconCreditLimit}
							to={creditLimitConfig.menuLink}
							className={itemClass}
							text={t(creditLimitConfig.menuName)}
							acl={creditLimitConfig.aclModule}
							testId="creditLimit"
						/>
						<Menu.Item
							icon={IconPromotions}
							to={promotionsConfig.menuLink}
							className={itemClass}
							text={t(promotionsConfig.menuName)}
							acl={promotionsConfig.aclModule}
							testId="promotions"
						/>
					</Menu.Group>
					<Menu.Group>
						<Menu.Item
							icon={IconPartners}
							to={partnersConfig.menuLink}
							className={itemClass}
							text={t(partnersConfig.menuName)}
							acl={partnersConfig.aclModule}
							testId="partners"
						/>
						<Menu.Item
							icon={IconCustomer}
							to={customersConfig.menuLink}
							className={itemClass}
							text={t(customersConfig.menuName)}
							acl={customersConfig.aclModule}
							isAllowed={!isGroupPartner}
							testId="customers"
						/>
						<Menu.Item
							icon={IconDistributionPartner}
							to={distributionPartnersConfig.menuLink}
							className={itemClass}
							text={t(distributionPartnersConfig.menuName)}
							acl={distributionPartnersConfig.aclModule}
							isAllowed={!isGroupPartner}
							testId="distributionPartners"
						/>
					</Menu.Group>
					<Menu.Group>
						<Menu.Item
							icon={IconPriceCalculator}
							to={priceCalculatorConfig.menuLink}
							className={itemClass}
							text={t(priceCalculatorConfig.menuName)}
							acl={priceCalculatorConfig.aclModule}
							testId="priceCalculator"
							end
						/>
					</Menu.Group>
				</Menu.List>
				<div className="mt-3">
					<Menu.List className="sidebar-menu mb-2">
						<Menu.Group>
							<Menu.Item
								icon={IconHelp}
								className={itemClass}
								text={t('menu.help')}
								onClick={() => helpModalRef.current?.show()}
								testId="help"
							/>
							<Menu.Item
								icon={IconFeedback}
								to={CONFIG.APP.FEEDBACK_URL}
								externalLink
								target="_blank"
								className={itemClass}
								text={t('menu.feedback')}
								testId="feedback"
								isAllowed={isGroupPartner}
							/>
						</Menu.Group>
					</Menu.List>
				</div>
				<div className="sidebar-footer text-white d-flex justify-content-between align-items-center">
					<Button variant="link" iconLeft={<IconArrowDoubleLeft />} onClick={() => sidebarCollapsed.toggle()} />
					{!sidebarCollapsed.value && <div className="text-gray fs-sm">OMS v{CONFIG.VERSION}</div>}
				</div>
			</div>
		</TestIdContextProvider>
	);
};

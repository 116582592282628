import type { IEntityOrder } from 'module/orders';
import type { TOrderInstanceProducts, TProductGroupFinder } from 'module/orders/hooks/orderToInstance';
import { isIspOrder, normalizeBusinessSku, normalizeSku } from 'module/orders/utils/common';
import type {
	IStandardOrderInstanceItem,
	IStandardOrderInstanceProduct,
	IStandardOrderItemPricing,
} from 'module/purchase';
import { orderInstanceNormalizer } from 'module/purchase/normalizer';
import { logParsedError } from 'js/utils/error';

const defaultFallback: TOrderInstanceProducts = {
	items: [],
	pricing: null,
	seqId: 0,
};

export const useOrderInstanceProducts = (
	order: IEntityOrder | null,
	productGroupFinder: TProductGroupFinder,
): TOrderInstanceProducts => {
	if (!order) {
		return defaultFallback;
	}

	const { getProductBySku, getProductGroupByCode } = productGroupFinder;

	let seqId = 0;
	const items: IStandardOrderInstanceItem[] = [];
	const pricingItems: IStandardOrderItemPricing[] = [];

	for (const item of order.lineItems) {
		const groupCode = item.ems?.order?.productGroup;
		const group = getProductGroupByCode(groupCode);
		let instanceItemProduct: IStandardOrderInstanceProduct | null = null;

		try {
			if (group) {
				const sku =
					group.marketSegment === 'BUSINESS' ? normalizeBusinessSku(item.product.sku) : normalizeSku(item.product.sku);
				const product = getProductBySku(sku, groupCode);
				if (product) {
					instanceItemProduct = orderInstanceNormalizer.normalizeProduct(group, product);
				} else if (isIspOrder(order)) {
					instanceItemProduct = orderInstanceNormalizer.normalizeIspItemToProduct(group, item);
				}
			}

			if (!instanceItemProduct) {
				instanceItemProduct = orderInstanceNormalizer.normalizeProductFallback(item);
			}
		} catch (error) {
			logParsedError({
				message: 'Order item normalization failed.',
				error,
				context: { itemId: item.id, orderId: order.id },
			});
			return defaultFallback;
		}

		const id = seqId++;
		items.push(orderInstanceNormalizer.normalizeItem(id, item, instanceItemProduct));
		pricingItems.push(orderInstanceNormalizer.normalizeItemPricing(id, item, order));
	}

	return {
		items,
		pricing: orderInstanceNormalizer.normalizePricing(order, pricingItems),
		seqId,
	};
};

import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum PartnerStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DENIED = 'DENIED',
	PENDING = 'PENDING',
}

export const partnerStatusEnumUtils = generateEnumUtils(PartnerStatusEnum, {
	translatePath: 'enums:partner.status',
});

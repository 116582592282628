import { logError } from 'js/utils/app';
import { upperFirst } from 'lodash';
import { ZodError } from 'zod';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

export class CustomError extends Error {
	constructor(message: string, context?: Record<string, unknown>, error?: unknown) {
		const errorMessage = error ? (error instanceof Error ? error.message : String(error)) : '';
		const contextInfo = parseContext(context);
		const parsedMessage = [message, errorMessage, contextInfo].filter(Boolean).join('\n');

		super(parsedMessage);
		this.name = 'CustomError';
	}
}

export const logParsedError = ({
	message,
	error,
	context,
}: { message: string; error: unknown; context?: Record<string, unknown> }) => {
	const contextInfo = parseContext(context);

	if (error instanceof Error) {
		// Logging error this way will include stack trace
		logError(`${message}\n${contextInfo}\n`, error);
	} else {
		logError([message, contextInfo, String(error)].join('\n'));
	}
};

const parseContext = (context?: Record<string, unknown>, itemsDevider = '\n') => {
	if (!context) {
		return '';
	}

	return Object.entries(context)
		.map((item) => item.join(': '))
		.join(itemsDevider);
};

export function solveCapturedValidationError<T extends { id: string | number }>(
	error: unknown,
	dataLabel: string,
	validationData: T,
) {
	const capitalizedLabel = upperFirst(dataLabel);
	const errorMessage = `${capitalizedLabel} Validation Error`;

	if (error instanceof ZodError) {
		Sentry.withScope((scope) => {
			// Group errors together based on their request and response
			scope.setFingerprint([error.name, dataLabel]);

			// Add extra context about the validation error
			scope.setExtra(`invalid${capitalizedLabel}Id`, validationData.id);
			scope.setExtra('validationErrors', error.errors);

			// Add specific fields that failed validation
			const invalidFields = error.errors.map((err) => err.path.join('.'));
			scope.setExtra('invalidFields', invalidFields);

			Sentry.addBreadcrumb({
				category: 'validation',
				message: `Attempted to validate ${dataLabel} ${validationData.id}`,
			});

			// Add more specific tags for better filtering
			Sentry.captureMessage(errorMessage, {
				tags: {
					validator: 'Zod',
					errorType: 'validation_error',
				},
				level: Severity.Error,
			});
		});
	} else {
		logError(error);
	}
}

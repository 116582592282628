import { Route, Routes } from 'react-router';
import { AuthRoute } from 'js/routes/AuthRoute';
import { ACL_READ } from 'config/acl';
import { Error404 } from 'module/error/page/Error404';
import { partnersConfig } from 'module/partners/partnersConfig';
import { PageList } from 'module/partners/pages';

export const PartnersRoutes = () => {
	const { aclModule } = partnersConfig;

	return (
		<Routes>
			<Route element={<AuthRoute acl={[aclModule, ACL_READ]} />}>
				<Route index element={<PageList />} />
			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};

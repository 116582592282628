import { Trans, useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { SubmitButton } from 'js/components/atoms/Button';
import type { IEntityPartnerTerms } from 'module/partners';
import { dateFormatter } from 'js/utils/dateTime';
import { Button } from '@avast/react-ui-components';
import { useAuthContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';
import { getSkipEligibleTermsFromList } from 'module/registration/utils/common';

type TTermsFormProps = {
	terms: IEntityPartnerTerms[];
	onSkip?(): void;
};

export const TermsFormActions = (props: TTermsFormProps) => {
	const { terms, onSkip } = props;
	const { t } = useTranslation(registrationConfig.trNamespace);
	const { authCompany } = useAuthContext();

	const skipEligibleTerms = getSkipEligibleTermsFromList(terms);
	const isSkipEnabled = !authCompany?.mustAcceptNewTerms && isDefined(onSkip) && Boolean(skipEligibleTerms);

	if (terms.length === 0) {
		return null;
	}

	return (
		<div className="vstack gap-4 mt-4 px-2">
			{isSkipEnabled && (
				<div className="fs-sm">
					<Trans
						i18nKey="page.terms.skip.message"
						values={{ date: dateFormatter.toTimeZoneDateTime(skipEligibleTerms?.effectiveFrom) }}
						t={t}
					/>
				</div>
			)}
			<div className="d-flex justify-content-end gap-2 align-items-center">
				{isSkipEnabled && (
					<Button variant="light" size="sm" onClick={onSkip}>
						{t('page.terms.skip.action')}
					</Button>
				)}
				<SubmitButton size="sm">{t('common:actions.accept')}</SubmitButton>
			</div>
		</div>
	);
};

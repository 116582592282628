import React, {
	createContext,
	type Dispatch,
	type PropsWithChildren,
	type SetStateAction,
	useCallback,
	useEffect,
	useReducer,
	useState,
} from 'react';
import {
	authContextInitialState,
	authContextStateReducer,
	type IAuthContextState,
	type IAuthData,
	type IAuthTokenResponse,
} from 'js/reducers/authContextReducer';
import type { IEntityPartnerIdentifiers, IPartnerRoleGroups, IPartnerRoles } from 'module/partners';
import { isAuthorizedBuyer } from 'module/partners/utils/partnerSelectors';
import type { TAuthContextSubscriberRef, TPartnerId, TPartnerSalesforceId } from 'types';
import { type TUseStorageContextOnChange, useStorageContext } from 'js/hooks/useStorageContext';
import { determinatePartnerRole, getPartnerRoleGroups, getPartnerRoles } from 'module/partners/utils/roles';
import { useRefreshAuthState } from 'module/security/hooks/useRefreshAuthState';
import { gtm } from 'js/analytics/gtm';
import { logDebug, logError } from 'js/utils/app';
import { jwtDecode } from 'jwt-decode';
import type { IJWTData } from 'module/security';
import { type PartnerRoleEnum, PartnerTypeEnum } from 'module/partners/enums';
import { useUserLocaleWatcher } from 'js/hooks/useUserLocaleWatcher';
import type { UserRoleEnum } from 'js/enums';
import { useOnNonAuthorizedCallback } from 'js/hooks/useOnNonAuthorizedCallback';
import invariant from 'invariant';

const AUTH_CONTEXT_STORAGE_KEY = 'auth';

export interface IAuthSecurity extends IPartnerRoles, IPartnerRoleGroups {
	authPartner: IAuthContextState['partner'];
	authPartnerId?: TPartnerId | null;
	authPartnerSalesforceId?: TPartnerSalesforceId | null;

	authCompany: IAuthContextState['partner'];
	authCompanyId?: TPartnerId | null;
	authCompanySalesforceId?: TPartnerSalesforceId | null;

	authRole: PartnerRoleEnum | null;
	isLoggedAsUser: boolean;
	isAuthorizedBuyer: boolean;
}

export interface IAuthContext extends IAuthSecurity {
	authState: IAuthContextState;
	isLogged: boolean;
	authPriceLists: IAuthContextState['priceLists'];
	authUserRoles: UserRoleEnum[];

	logout: () => void;
	setAuthToken: (token: IAuthTokenResponse) => void;
	setAuthCompany: (company: IAuthContextState['company']) => void;
	setAuthPartner: (partner: IAuthContextState['partner']) => void;
	setAuthData: (data: IAuthData) => void;
	setLocaleFormat: (code: IAuthContextState['locale']['format']) => void;
	updateTaxId: (taxId: IEntityPartnerIdentifiers['partyTaxId']) => void;

	isAcceptanceOfTermsSkipped: boolean;
	setIsAcceptanceOfTermsSkipped: Dispatch<SetStateAction<boolean>>;
}

interface IAuthContextProviderProps {
	authContextSubscriberRef: TAuthContextSubscriberRef;
}

const AuthContext = createContext<IAuthContext | null>(null);
AuthContext.displayName = 'AuthContext';

/**
 * Hook to get auth context
 */
export const useAuthContext = () => {
	const context = React.useContext(AuthContext);

	invariant(
		context !== null,
		'Auth context is undefined, please verify you are calling useAuthContext() as child of a <AuthContextProvider> component.',
	);

	return context;
};

export const AuthContextProvider = (props: PropsWithChildren<IAuthContextProviderProps>) => {
	const { children, authContextSubscriberRef } = props;
	const [authState, dispatch] = useReducer(authContextStateReducer, authContextInitialState);
	const onNonAuthorized = useOnNonAuthorizedCallback();
	const [isAcceptanceOfTermsSkipped, setIsAcceptanceOfTermsSkipped] = useState(false);

	// Set JWT
	const setAuthToken: IAuthContext['setAuthToken'] = useCallback((payload) => {
		dispatch({ type: 'SET_AUTH_TOKEN', payload });
	}, []);

	// Logout
	const logout: IAuthContext['logout'] = useCallback(() => {
		logDebug('Do logout');
		dispatch({ type: 'RESET' });
	}, []);

	// Refresh token
	const refreshAuthState = useRefreshAuthState();

	// Sync context
	const onChangeContext: TUseStorageContextOnChange<IAuthContextState> = useCallback(
		async (action, payload) => {
			let refreshAuthStatePromise: IAuthContextState | null;

			switch (action) {
				case 'LOAD':
					refreshAuthStatePromise = await refreshAuthState(payload, onNonAuthorized);
					dispatch({ type: 'INIT', payload: refreshAuthStatePromise });
					if (refreshAuthStatePromise) {
						authContextSubscriberRef.current?.onLogin(refreshAuthStatePromise as IAuthData);
					}
					break;
				case 'CHANGE':
					if (payload) {
						dispatch({ type: 'SET', payload });
					} else {
						logout();
					}
					break;
				default:
					logError(`Not supported action: ${action}`);
			}
		},
		[logout, refreshAuthState, authContextSubscriberRef, onNonAuthorized],
	);

	useStorageContext<IAuthContextState>({
		state: authState,
		key: AUTH_CONTEXT_STORAGE_KEY,
		onChange: onChangeContext,
		allowUpdateState: authState?.isReady,
		allowLoadState: !authState?.isReady,
	});

	const authSecurity = getAuthSecurity(authState.partner, authState.company);
	const isLogged: IAuthContext['isLogged'] =
		Boolean(authSecurity.authPartnerId) &&
		Boolean(authState.token) &&
		Boolean(authState.partner) &&
		!isJwtTokenExpired(authState.token);

	const setAuthCompany: IAuthContext['setAuthCompany'] = useCallback((payload) => {
		dispatch({ type: 'SET_AUTH_COMPANY', payload });
	}, []);

	const setAuthPartner: IAuthContext['setAuthPartner'] = useCallback((payload) => {
		dispatch({ type: 'SET_AUTH_PARTNER', payload });
	}, []);

	const setAuthData: IAuthContext['setAuthData'] = useCallback(
		(payload) => {
			dispatch({ type: 'SET_AUTH_DATA', payload });
			authContextSubscriberRef.current?.onLogin(payload);
		},
		[authContextSubscriberRef],
	);

	const setLocaleFormat: IAuthContext['setLocaleFormat'] = useCallback((payload) => {
		dispatch({ type: 'SET_LOCALE_FORMAT', payload });
	}, []);

	const updateTaxId: IAuthContext['updateTaxId'] = useCallback((payload) => {
		dispatch({ type: 'UPDATE_TAX_ID', payload });
	}, []);

	const { authRole } = authSecurity;
	useEffect(() => {
		if (authRole) {
			gtm.user({ userRole: authRole });
		}
	}, [authRole]);

	const value: IAuthContext = {
		authState,
		...authSecurity,
		isLogged,
		isAcceptanceOfTermsSkipped,
		setIsAcceptanceOfTermsSkipped,
		authPriceLists: authState.priceLists,
		authUserRoles: getUserRolesFromJwtToken(authState.token),
		setAuthToken,
		setAuthCompany,
		setAuthPartner,
		setAuthData,
		setLocaleFormat,
		updateTaxId,
		logout,
	};

	useUserLocaleWatcher(value);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const getAuthSecurity = (
	authPartner: IAuthContextState['partner'],
	authCompany: IAuthContextState['company'],
): IAuthSecurity => {
	const isLoggedAsUser = authPartner?.accountType === PartnerTypeEnum.USER;
	const company = isLoggedAsUser ? authCompany : authPartner;

	const authRole = determinatePartnerRole(authPartner, company);
	const authRoles = getPartnerRoles(authRole);
	const authRoleGroups = getPartnerRoleGroups(authRoles);

	return {
		isAuthorizedBuyer: isAuthorizedBuyer(authPartner) || authRoleGroups.isGroupSales,
		isLoggedAsUser,
		// Partner
		authPartner,
		authPartnerId: authPartner?.id,
		authPartnerSalesforceId: authPartner?.salesforceId,
		// Company
		authCompany: company,
		authCompanyId: company?.id,
		authCompanySalesforceId: company?.salesforceId,
		// Roles
		authRole,
		...authRoleGroups,
		...authRoles,
	};
};

const isJwtTokenExpired = (token: IAuthContextState['token']): boolean => {
	const currentTime = Date.now() / 1000;
	const tokenData = token ? jwtDecode<IJWTData>(token) : null;
	return currentTime > (tokenData?.exp || 0);
};

export const getUserRolesFromJwtToken = (token: IAuthContextState['token']): UserRoleEnum[] => {
	const tokenData = token ? jwtDecode<IJWTData>(token) : null;
	return tokenData?.roles || [];
};

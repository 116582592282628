import type { TAnnouncement } from 'submodule/Announcements/types';
import { Trans, useTranslation } from 'react-i18next';
import { useAuthContext } from 'js/contexts';
import { useApiPartnerNotAcceptedTermsList } from 'module/partners/hooks/useApiPartners';
import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import { dateFormatter } from 'js/utils/dateTime';
import { getSkipEligibleTermsFromList } from 'module/registration/utils/common';

export const useNewTermsAvailableAnnouncement = (): TAnnouncement => {
	const { t } = useTranslation('submodules');
	const { authCompany, isGroupPartner, isLoggedAsUser } = useAuthContext();
	const isEnabled = Boolean(authCompany?.shouldAcceptNewTerms) && isGroupPartner;
	const {
		data: termsList,
		query: { isPending },
	} = useApiPartnerNotAcceptedTermsList({
		filter: { partnerId: authCompany?.id },
		queryConfig: {
			enabled: isEnabled,
		},
	});
	const skipEligibleTerms = getSkipEligibleTermsFromList(termsList);

	return {
		id: 'fdc6bb73-a759-41e1-bf53-967a26e01894',
		isStatic: true,
		show: isEnabled && !isPending,
		variant: 'warning',
		caption() {
			return isLoggedAsUser
				? t('announcements.newTermsAvailable.user.title')
				: t('announcements.newTermsAvailable.primary.title');
		},
		render() {
			if (isLoggedAsUser) {
				return (
					<p>
						<Trans
							t={t}
							values={{ date: dateFormatter.toTimeZoneDateTime(skipEligibleTerms?.effectiveFrom) }}
							i18nKey="announcements.newTermsAvailable.user.text"
						/>
					</p>
				);
			}

			return (
				<>
					<p>
						<Trans
							t={t}
							values={{ date: dateFormatter.toTimeZoneDateTime(skipEligibleTerms?.effectiveFrom) }}
							i18nKey="announcements.newTermsAvailable.primary.text"
						/>
					</p>
					<p className="mt-3">
						<LinkButton to={RouteName.REGISTRATION.TERMS} size="xs" variant="outline-warning">
							{t('announcements.newTermsAvailable.primary.action')}
						</LinkButton>
					</p>
				</>
			);
		},
		placement: ['HOMEPAGE_PARTNER'],
	};
};

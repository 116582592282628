import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import type { IEntityPartnerDetail } from 'module/partners';
import { useAppContext } from 'js/contexts';

type TUsePartnerTermsResponse = (partner: IEntityPartnerDetail) => Promise<boolean>;

/**
 * Resolve if a partner should be added to order even when he has not accepted terms
 * @returns {TUsePartnerTermsResponse}
 */
export const usePartnerTermsGuard = (): TUsePartnerTermsResponse => {
	const { t } = useTranslation(purchaseConfig.trNamespace);
	const { confirmationModalRef } = useAppContext();

	return useCallback(
		async (partner) => {
			// Terms are accepted
			if (!partner.mustAcceptNewTerms) {
				return true;
			}

			// Resolution by user
			return Boolean(
				await confirmationModalRef.current?.show({
					title: t('guards.partnerTermsGuard.title'),
					messages: [t('guards.partnerTermsGuard.message')],
					submitButtonText: t('common:actions.continue'),
				}),
			);
		},
		[t, confirmationModalRef],
	);
};

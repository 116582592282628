import { useCallback } from 'react';
import { useAsyncApiPartnerDetail } from 'module/partners/api/useAsyncApiPartners';
import { isEqual } from 'lodash';
import type { IOrderReducerSetPartnerPayload } from 'js/reducers/orderReducer';
import type { IEntityPartnerDetail } from 'module/partners';
import type { TPartnerPriceLists } from 'js/priceList';
import { useAsyncApiPartnerPriceLists } from 'js/priceList/useAsyncApiPartnerPriceLists';
import type { IRefreshOrderStateProps } from 'js/hooks/useRefreshOrderState';
import { isDefined } from 'js/utils/common';
import invariant from 'invariant';
import { CustomError } from 'js/utils/error';

export const useRefreshOrderPartnerState = () => {
	const asyncApiPartnerDetail = useAsyncApiPartnerDetail();
	const asyncApiPartnerPriceLists = useAsyncApiPartnerPriceLists();

	return useCallback(
		async (props: IRefreshOrderStateProps) => {
			const { state, authData, dispatch } = props;

			// Partner
			invariant(isDefined(state.partner?.id), 'Order state is missing a partner.');
			invariant(isDefined(state.partnerPriceLists), 'Order state is missing partnerPriceLists.');

			let refreshedPartner: IEntityPartnerDetail | null = null;
			let refreshedPriceLists: TPartnerPriceLists | null = null;

			// Take partner data from Auth data
			if (state.partner.id === authData?.company?.id) {
				refreshedPartner = authData.company;
				refreshedPriceLists = authData.priceLists;
			}

			// Otherwise from API
			else {
				let partnerResponse: Awaited<ReturnType<typeof asyncApiPartnerDetail>>;
				let priceListsResponse: Awaited<ReturnType<typeof asyncApiPartnerPriceLists>>;

				try {
					partnerResponse = await asyncApiPartnerDetail(state.partner.id, {
						authToken: authData?.token,
					});
				} catch (error) {
					throw new CustomError('Getting Partner detail failed:', { partnerId: state.partner.id }, error);
				}

				try {
					priceListsResponse = await asyncApiPartnerPriceLists(state.partner.id, {
						authToken: authData?.token,
					});
				} catch (error) {
					throw new CustomError('Getting Partner Price list failed:', { partnerId: state.partner.id }, error);
				}

				if (partnerResponse && priceListsResponse) {
					refreshedPartner = partnerResponse.data;
					refreshedPriceLists = priceListsResponse;
				}
			}

			const payload: IOrderReducerSetPartnerPayload = {
				partner: refreshedPartner || state.partner,
				partnerPriceLists: refreshedPriceLists || state.partnerPriceLists,
			};

			if (!isEqual(state.partner, refreshedPartner) || !isEqual(state.partnerPriceLists, refreshedPriceLists)) {
				dispatch({ type: 'SET_PARTNER', payload });
			}

			return payload;
		},
		[asyncApiPartnerDetail, asyncApiPartnerPriceLists],
	);
};

import type { PartnerRoleEnum } from 'module/partners/enums';
import type { IAuthContext } from 'js/contexts/AuthContext';
import { isFunction } from 'lodash';
import { ThemeEnum, UserRoleEnum } from 'js/enums';
import { CONFIG } from 'config/index';

// No permission
const ACL_NO = 0;
// Create
const ACL_CREATE = 1;
// Read
const ACL_READ_LIST = 2;
const ACL_READ_DETAIL = 4;
const ACL_READ = ACL_READ_LIST | ACL_READ_DETAIL;
// Update
const ACL_UPDATE = 8;
// Delete
const ACL_DELETE = 16;
// Allow all
const ACL_ALL = ACL_CREATE | ACL_READ | ACL_UPDATE | ACL_DELETE;

// Available modules
export type TAclModules =
	| 'distributionPartners'
	| 'licenses'
	| 'purchase'
	| 'creditStatus'
	| 'promotions'
	| 'closingBalance'
	| 'orders'
	| 'quotes'
	| 'account'
	| 'registration'
	| 'customers'
	| 'creditLimit'
	| 'retail'
	| 'priceCalculator'
	| 'partners'
	| 'flexibleBilling';

export type TAclAction = number;
type TAclActionResolver = (authContext: IAuthContext) => TAclAction;
type TAclUserRoleRule = [UserRoleEnum, TAclAction];
type TAclUserRoleRules = TAclUserRoleRule[];

type TAclByUserRole = Record<TAclModules, TAclActionResolver>;
type TAclByPartnerRole = Record<PartnerRoleEnum, Record<TAclModules, TAclAction | TAclActionResolver>>;

const aclResolver = (rules: TAclUserRoleRules): TAclActionResolver => {
	return (authContext) => {
		// Merge allowed roles
		return rules.reduce((acc: TAclAction, [key, value]) => {
			const isRoleAllowed = authContext.authUserRoles.includes(key);
			if (isRoleAllowed) {
				return acc | value;
			}
			return acc;
		}, ACL_NO);
	};
};

/**
 * Can rules
 * @type {TAclByPartnerRole}
 */
const aclByPartnerRoleAvast: TAclByPartnerRole = {
	SALES_OPERATIONS: {
		distributionPartners: ACL_READ,
		licenses: ACL_ALL,
		purchase: ACL_ALL,
		creditStatus: ACL_ALL,
		promotions: ACL_ALL,
		closingBalance: ACL_ALL,
		orders: ACL_ALL,
		quotes: ACL_ALL,
		partners: ACL_NO,
		customers: ACL_ALL,
		account: ACL_ALL,
		registration: ACL_NO,
		creditLimit: ACL_ALL,
		retail: ACL_ALL,
		priceCalculator: ACL_ALL,
		flexibleBilling: ACL_NO,
	},
	SALES_MANAGER: {
		distributionPartners: ACL_READ,
		licenses: ACL_ALL,
		purchase: ACL_ALL,
		creditStatus: ACL_ALL,
		promotions: ACL_NO,
		closingBalance: ACL_ALL,
		orders: ACL_ALL,
		quotes: ACL_ALL,
		partners: ACL_NO,
		customers: ACL_ALL,
		account: ACL_ALL,
		registration: ACL_NO,
		creditLimit: ACL_READ,
		retail: ACL_NO,
		priceCalculator: ACL_ALL,
		flexibleBilling: ACL_NO,
	},
	RESELLER: {
		distributionPartners: ACL_NO,
		licenses: ACL_ALL,
		purchase: ACL_ALL,
		creditStatus: ACL_READ,
		promotions: ACL_NO,
		closingBalance: ACL_ALL,
		orders: ACL_ALL,
		quotes: ACL_ALL,
		partners: ACL_NO,
		customers: ACL_ALL,
		account: ACL_ALL,
		registration: ACL_ALL,
		creditLimit: ACL_NO,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
	},
	DISTRIBUTOR: {
		distributionPartners: ACL_ALL,
		licenses: ACL_ALL,
		purchase: ACL_ALL,
		creditStatus: ACL_READ,
		promotions: ACL_NO,
		closingBalance: ACL_ALL,
		orders: ACL_ALL,
		quotes: ACL_ALL,
		partners: ACL_NO,
		customers: ACL_ALL,
		account: ACL_ALL,
		registration: ACL_ALL,
		creditLimit: ACL_NO,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_ALL,
	},
	FINANCE: {
		orders: ACL_READ,
		quotes: ACL_READ,
		purchase: ACL_NO,
		closingBalance: ACL_READ,
		licenses: ACL_READ,
		creditStatus: ACL_READ,
		customers: ACL_NO,
		partners: ACL_NO,
		distributionPartners: ACL_NO,
		promotions: ACL_READ,
		account: ACL_ALL,
		registration: ACL_NO,
		creditLimit: ACL_ALL,
		retail: ACL_READ,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
	},
	SUPPORT: {
		orders: ACL_READ,
		quotes: ACL_READ,
		purchase: ACL_NO,
		closingBalance: ACL_READ,
		licenses: ACL_ALL,
		creditStatus: ACL_READ,
		customers: ACL_NO,
		partners: ACL_NO,
		distributionPartners: ACL_NO,
		promotions: ACL_NO,
		account: ACL_ALL,
		registration: ACL_NO,
		creditLimit: ACL_READ,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
	},
};

const aclByPartnerRoleEmpower: TAclByPartnerRole = {
	SALES_OPERATIONS: {
		distributionPartners: ACL_NO,
		licenses: ACL_ALL,
		purchase: ACL_ALL,
		creditStatus: ACL_NO,
		promotions: ACL_NO,
		closingBalance: ACL_NO,
		orders: ACL_ALL,
		quotes: ACL_NO,
		customers: ACL_NO,
		account: ACL_ALL,
		registration: ACL_NO,
		creditLimit: ACL_NO,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
		partners: ACL_ALL,
	},
	SALES_MANAGER: {
		distributionPartners: ACL_NO,
		licenses: ACL_ALL,
		purchase: ACL_ALL,
		creditStatus: ACL_NO,
		promotions: ACL_NO,
		closingBalance: ACL_NO,
		orders: ACL_ALL,
		quotes: ACL_NO,
		customers: ACL_NO,
		account: ACL_ALL,
		registration: ACL_NO,
		creditLimit: ACL_NO,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
		partners: ACL_ALL,
	},
	RESELLER: {
		distributionPartners: ACL_NO,
		licenses: ACL_ALL,
		purchase: ACL_ALL,
		creditStatus: ACL_NO,
		promotions: ACL_NO,
		closingBalance: ACL_NO,
		orders: ACL_ALL,
		quotes: ACL_NO,
		customers: ACL_NO,
		account: ACL_ALL,
		registration: ACL_ALL,
		creditLimit: ACL_NO,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
		partners: ACL_NO,
	},
	DISTRIBUTOR: {
		distributionPartners: ACL_NO,
		licenses: ACL_ALL,
		purchase: ACL_ALL,
		creditStatus: ACL_NO,
		promotions: ACL_NO,
		closingBalance: ACL_NO,
		orders: ACL_ALL,
		quotes: ACL_NO,
		customers: ACL_NO,
		account: ACL_ALL,
		registration: ACL_ALL,
		creditLimit: ACL_NO,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
		partners: ACL_NO,
	},
	FINANCE: {
		orders: ACL_READ,
		quotes: ACL_NO,
		purchase: ACL_NO,
		closingBalance: ACL_NO,
		licenses: ACL_READ,
		creditStatus: ACL_NO,
		customers: ACL_NO,
		distributionPartners: ACL_NO,
		promotions: ACL_NO,
		account: ACL_ALL,
		registration: ACL_NO,
		creditLimit: ACL_NO,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
		partners: ACL_READ,
	},
	SUPPORT: {
		orders: ACL_READ,
		quotes: ACL_NO,
		purchase: ACL_NO,
		closingBalance: ACL_NO,
		licenses: ACL_ALL,
		creditStatus: ACL_NO,
		customers: ACL_NO,
		distributionPartners: ACL_NO,
		promotions: ACL_NO,
		account: ACL_ALL,
		registration: ACL_NO,
		creditLimit: ACL_NO,
		retail: ACL_NO,
		priceCalculator: ACL_NO,
		flexibleBilling: ACL_NO,
		partners: ACL_READ,
	},
};

const aclByPartnerRole = CONFIG.THEME === ThemeEnum.EMPOWER ? aclByPartnerRoleEmpower : aclByPartnerRoleAvast;

const aclByUserRole: Partial<TAclByUserRole> = {
	flexibleBilling: aclResolver([
		[UserRoleEnum.ROLE_FLEXIBLE_BILLING_READER, ACL_READ],
		[UserRoleEnum.ROLE_FLEXIBLE_BILLING_EDITOR, ACL_ALL],
	]),
};

/**
 * Check permission
 * @param {IAuthContext} context
 * @param {TAclModules} module
 * @param {TAclAction} action
 * @returns {boolean}
 */
const aclCheck = (context: IAuthContext, module: TAclModules, action: TAclAction): boolean => {
	if (context.authRole === null) {
		return false;
	}

	// Partner
	let allowedActions = aclByPartnerRole[context.authRole][module];
	if (isFunction(allowedActions)) {
		allowedActions = allowedActions(context);
	}

	// Role
	const resolverByRole = aclByUserRole[module];
	if (resolverByRole) {
		allowedActions &= resolverByRole(context);
	}

	return Boolean(allowedActions & action);
};

export { aclCheck, ACL_READ, ACL_READ_DETAIL, ACL_READ_LIST, ACL_CREATE, ACL_DELETE, ACL_UPDATE, ACL_ALL };

export const exportedForTesting = {
	aclResolver,
};

import { Alert, H2 } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { logError } from 'js/utils/app';
import type { FormikConfig } from 'formik';
import { Formik } from 'formik';
import { FormikForm } from 'js/components/formik/FormikForm';
import { useApiPartnerNotAcceptedTermsList } from 'module/partners/hooks/useApiPartners';
import type { TPartnerId } from 'types';
import { TermsFormContent } from 'module/registration/forms/TermsFormContent';
import { TermsFormActions } from 'module/registration/forms/TermsFormActions';
import type { FormikValues } from 'formik/dist/types';

type TTermsFormProps = {
	onSubmit: FormikConfig<FormikValues>['onSubmit'];
	onSkip?(): void;
	partnerId: TPartnerId | null;
};

export const TermsForm = (props: TTermsFormProps) => {
	const { onSubmit, partnerId, onSkip } = props;
	const { t } = useTranslation(registrationConfig.trNamespace);
	const {
		data,
		query: { isPending, isError },
	} = useApiPartnerNotAcceptedTermsList({
		filter: { partnerId },
		queryConfig: {
			meta: {
				onError(error) {
					logError(`No terms could be loaded. Partner id is ${partnerId}`, error);
				},
			},
		},
	});

	if (isError || (!isPending && data.length === 0)) {
		return <Alert variant="warning" caption={t('error.unableToLoadTerms')} />;
	}

	return (
		<Formik initialValues={{}} onSubmit={onSubmit}>
			<FormikForm testId="partnerTermsForm">
				<H2 className="text-center mb-8" bold>
					{t('page.terms.title')}
				</H2>
				{isPending && <LoadingPlaceholder minHeight={300} />}
				<TermsFormContent terms={data} />
				<TermsFormActions terms={data} onSkip={onSkip} />
			</FormikForm>
		</Formik>
	);
};
